<template>
  <b-card>
    <h5 class="mb-2">
      {{ $t('MY_GROUPS') }}
    </h5>

    <!-- suggested pages -->
    <div
      v-for="(data,index) in groups"
      :key="index"
      class="d-flex justify-content-start align-items-center mt-1"
    >
      <div class="mr-1">
        <feather-icon
          icon="UsersIcon"
          size="24"
          class="mr-50 text-primary"
        />
      </div>
      <div class="profile-user-info">
        <h6 class="mb-0">
          {{ data.groupname }}
        </h6>
        <!-- <small class="text-muted">{{ data.colorCode }}</small> -->
      </div>
      <div class="profile-star ml-auto">
        <feather-icon
          icon="StarIcon"
          size="18"
        />
      </div>
    </div>
    <!--/ suggested pages -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  props: {
    groups: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
