<template>
  <div
    v-if="Object.keys(user).length"
    id="user-profile"
  >
    <profile-header :user="user" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-row>
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <profile-about :user="user" />
          <profile-group :groups="groups" />
        </b-col>

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <profile-habit :performed-tasks="performedTasks" />
        </b-col>
        <!-- post -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        >
          <profile-game
            :games="games"
            :header-text="$t('MY_GAMES')"
          />
        </b-col>
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import mixinAlert from '@/constants/mixinAlert'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import {
  updateBreadcrumb,
} from '@/constants/utils'
import constants from '@/constants/static.json'
import ProfileHeader from './ProfileHeader.vue'
import ProfileAbout from './ProfileAbout.vue'
import ProfileGroup from './ProfileGroup.vue'
import ProfileHabit from './ProfileHabit.vue'
import ProfileGame from './ProfileGame.vue'

export default {
  components: {
    BRow,
    BCol,
    ProfileHeader,
    ProfileAbout,
    ProfileGroup,
    ProfileHabit,
    ProfileGame,
  },
  data() {
    return {
      games: [],
      user: { },
      performedTasks: [],
      groups: [],
      routeId: 0,
      breadCrumb: constants.BREADCRUMB,
    }
  },
  created() {
    const routeId = router.currentRoute.params.id
    const userId = routeId || useJwt.getUser().id
    this.routeId = routeId || null
    this.fetchUser(userId)
  },
  methods: {
    ...mapActions('user', ['updateUser',
      'fetchUserByID',
      'fetchGroupGameByUserID',
      'fetchPerformedTasksByUserID']),
    async fetchUser(id) {
      try {
        await this.fetchUserByID(id).then(response => {
          if (response) {
            const { data } = response.data
            this.user = data
            this.configureBreadcrumbs()
          }
        })
      } catch (error) {
        // this.showErrorMessage()
      }
    },
    configureBreadcrumbs() {
      const company = useJwt.getBreadcrumb(this.breadCrumb.COMPANY)
      const breadcrumbs = [
        { text: company.name, to: `/companies/company-view/${company.id}`, active: false },
        {
          text: `${this.user.firstName || ''} ${this.user.lastName || ''}`.trim() || 'User Configuration',
          active: true,
        },
      ]
      updateBreadcrumb(this, breadcrumbs)
    },
    async fetchUserPerformedTasks(id) {
      try {
        await this.fetchPerformedTasksByUserID(id).then(response => {
          if (response) {
            this.performedTasks = response
          }
        })
      } catch (error) {
        // this.showErrorMessage()
      }
    },
    async fetchGroupGameByID(id) {
      try {
        await this.fetchGroupGameByUserID(id).then(response => {
          if (response) {
            const results = response
            results.forEach(e => {
              this.groups.push(e.group)
              e.group.competition_groups.forEach(c => {
                if (c.competition) {
                  this.games.push(c.competition)
                }
              })
            })
          }
        })
      } catch (error) {
        // this.showErrorMessage()
      }
    },
  },
  setup() {
    const {
      showErrorMessage,
    } = mixinAlert()

    return {
      showErrorMessage,
      updateBreadcrumb,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
