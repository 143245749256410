<template>
  <div>
    <h5 class="mb-2">
      {{ $t('MY_HABIT_BEHAVIOUR') }}
    </h5>
    <b-card
      v-for="(data) in performedTasks"
      :key="data.performedtaskID"
    >
      <div
        class="d-flex justify-content-start align-items-center mb-1"
      >
        <!-- avatar -->
        <feather-icon
          icon="ActivityIcon"
          size="24"
          class="mr-50 text-primary"
        />
        <!--/ avatar -->
        <div class="profile-user-info">
          <h6 class="mb-0">
            {{ strSubstring(data.taskbeskrivning,15) }}
          </h6>
          <small class="text-muted mr-1">{{ formatDateTime(data.datetime) }}</small>
          <b-link class="text-body">
            <feather-icon
              icon="StarIcon"
              size="18"
              class="mr-25 text-primary"
            />
            <span class="text-muted align-middle">{{ data.points }}</span>
          </b-link>
        </div>
      </div>
      <!-- comments -->
      <div
        v-for="(subtasks) in data.performedsubtasks"
        :key="subtasks.ID"
        class="d-flex align-items-start mb-1"
      >
        <feather-icon
          icon="FileTextIcon"
          size="24"
          class="mr-50 text-primary"
        />
        <div class="profile-user-info w-100">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">
              {{ strSubstring(subtasks.subtaskstring,15) }}
            </h6>
            <b-link class="text-body">
              <feather-icon
                icon="StarIcon"
                size="18"
                class="mr-25 text-primary"
              />
              <span class="text-muted align-middle">{{ subtasks.subtaskpoint }}</span>
            </b-link>
          </div>
          <small>{{ formatDateTime(data.datetime) }}</small>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-extraneous-dependencies
import { kFormatter } from '@core/utils/filter'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import { mixinList } from '@/constants/mixinValidations'

export default {
  components: {
    BCard,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinDate, mixinList],
  props: {
    performedTasks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    kFormatter,
  },
}
</script>
